// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-riksdagen-js": () => import("/opt/build/repo/src/pages/projects/riksdagen.js" /* webpackChunkName: "component---src-pages-projects-riksdagen-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-surveys-mapping-sida-partner-evaluation-js": () => import("/opt/build/repo/src/pages/surveys/mapping_sida_partner_evaluation.js" /* webpackChunkName: "component---src-pages-surveys-mapping-sida-partner-evaluation-js" */),
  "component---src-pages-toolssection-js": () => import("/opt/build/repo/src/pages/toolssection.js" /* webpackChunkName: "component---src-pages-toolssection-js" */)
}

